import React from 'react'
import Img from "gatsby-image"
import { Link } from "gatsby"

export default function card({title, img, desc, href}) {
  return (
    <div className="col-sm-6 col-lg-4 mb-3 mb-sm-8">
      <article className="card h-100">
        <div className="card-img-top position-relative">
          <Link className="text-inherit" to={href}>
            <Img fluid={img} alt={title} title={title} className="card-img-top" />
          </Link>

          <figure className="ie-curved-y position-absolute right-0 bottom-0 left-0 mb-n1">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1920 100.1">
              <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"/>
            </svg>
          </figure>
        </div>

        <div className="card-body">
          <h3>
            <Link className="text-inherit" to={href}>{title}</Link>
          </h3>
          <p>{desc}</p>
        </div>
      </article>
    </div>
  )
}
