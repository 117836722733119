import React from "react"
import SingleLayout from '../components/layouts/SingleLayout_10'
import Seo from "../components/seo"
// import kebabCase from "lodash/kebabCase"
import { Link, graphql } from "gatsby"
import LatestPosts from '../components/content_utils/latest_posts'
const _ = require("lodash");

const TagsPage = ({ data }) => {
  const title = "Tags and Categories for GyanBlog"
  const hero = {
    title:"Categories and Tags", 
    image: "/images/tags.jpg"
  }

  return (
    <>
      <SingleLayout hero={hero}>
        <Seo 
          title={title} 
          description="Explore Tags and Categories which includes stackoverflow kind of problems, coding interview questions and SEO"
          image="/images/tags.jpg"
          />
        
        <h2>All Categories</h2>
        <div className="row mb-3">
          {data.categoriesGroup.group.map((category, idx) => (
            <div key={idx} className="col-sm-6 col-lg-3 mb-3 mb-sm-8">
              <article className="card bg-navy text-white h-100">
                <div className="card-body p-4 p-lg-5">
                  <h3 className="h3">
                    <Link className="text-white" 
                      to={`/${_.replace(_.toLower(category.fieldValue), / /g, '-')}/`}>
                      {_.startCase(_.toLower(category.fieldValue))}
                    </Link>
                  </h3>
                  {/* <p>A new tutorial to make it easier to master Adobe Ai.</p> */}
                </div>
              </article>
            </div>
            ))}
        </div>

        <h2>All Tags</h2>
        <div className="row mb-3">
          {data.tagsGroup.group.map((tag, idx) => (
            <div key={idx} className="col-sm-6 col-lg-3 mb-3 mb-sm-8">
              <article className="card bg-secondary text-white h-100">
                <div className="card-body p-4 p-lg-5">
                  <h3 className="h4">
                    <Link className="text-white" 
                      to={`/tags/${_.replace(_.toLower(tag.fieldValue), / /g, '-')}/`}>
                      {_.capitalize(_.toLower(tag.fieldValue))}
                    </Link>
                  </h3>
                  {/* <p>A new tutorial to make it easier to master Adobe Ai.</p> */}
                </div>
              </article>
            </div>
            ))}
        </div>
          
        <hr />
        <LatestPosts
          title="Latest Posts"
          currentPostSlug="/tags/" 
          maxPosts="6" />
        
      </SingleLayout>
    </>
  )
}

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    tagsGroup:allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    categoriesGroup:allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___categories) {
        fieldValue
        totalCount
      }
    }
  }
`